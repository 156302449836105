.react-datepicker-wrapper {
  width: 100%;
  box-sizing: border-box;
}

.react-datepicker__input-container input {
  /* 
  line-height: 1rem;
  padding: 0.2rem;
  box-sizing: border-box;
  border: 0.1rem solid #ccc;
  border-radius: 0.3rem;
  cursor: pointer; */
  width: 100%;
  text-align: center;
  font-size: 1rem;
  box-sizing: border-box;
}

.react-datepicker__input-container input:hover {
  width: 100%;
  background-color: #90ba27;
  color: white;
}
